/*
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-08-25 09:59:47
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-26 17:26:47
 */

import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";


// 保存商城商品
export const saveGoods = data => {
  return api({
    url: '/admin/shop/goods/saveGoods',
    method: 'post',
    data
  });
};
// 获取索引店铺名称
export const storeAll = data => {
  return api({
    url: '/admin/wx_open/wx_store_config/all',
    method: 'post',
    data
  });
};
// 保存微信小商店商品
export const saveWxGoods = data => {
  return api({
    url: '/admin/shop/goods/saveWxGoods',
    method: 'post',
    data
  });
};
// 获取商城商品
export const getGoods = data => {
  return api({
    url: '/admin/shop/goods/detail',
    method: 'post',
    data
  });
};
// 获取通用地址
export const getCityJson = data => {
  return api({
    url: '/admin/admin/data/getCityJson',
    method: 'post',
    data
  });
};


<template>
  <div class="specs">
    <el-form
      label-position="left"
      ref="form"
      label-width="80px"
      v-if="specs.length"
    >
      <el-form-item v-if="attrs.length" label="批量设置：">
        <el-select
          style="min-width: 80px; margin-right: 5px"
          v-model="form.options[options.name]"
          placeholder="请选择"
          v-for="(options, optionsIndex) in specsOptions"
          :key="options.id"
        >
          <el-option
            v-for="item in options.arr"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="attrs.length" style="margin-top: 10px">
        <template v-if="goodsForm.pay_type === 0">
          <el-input
            type="number"
            style="width: 150px; margin-right: 5px"
            placeholder="请输入售卖价格"
            v-model="form.price"
            :max="999999"
            maxlength="6"
          ></el-input>
          <el-input
            type="number"
            style="width: 150px; margin-right: 5px"
            placeholder="请输入市场价"
            v-model="form.market_price"
            :max="999999"
            maxlength="6"
          ></el-input>
        </template>
        <el-input
          v-else
          type="number"
          style="width: 150px; margin-right: 5px"
          placeholder="请输入积分"
          v-model="form.integral"
          :max="999999"
          maxlength="6"
        ></el-input>

        <el-input
          type="number"
          style="width: 150px; margin-right: 5px"
          placeholder="请输入库存"
          v-model="form.stocks"
          :max="999999"
          maxlength="6"
        ></el-input>
        <el-button type="primary" @click="batchSet">设置</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="specs" style="width: 100%">
      <el-table-column prop="config" label="规格">
        <template slot-scope="scope">
          <div class="flex-align-center">
            <span>{{ scope.row.config.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="image" label="规格图片">
        <template slot-scope="scope">
          <div class="flex-align-center">
            <!-- 属性图片 -->
            <list-image
              v-if="scope.row.image"
              :src="scope.row.image"
              style="margin-left: 10px"
              fit="cover"
            ></list-image>
            <el-button
              style="padding: 8px; margin-left: 10px"
              icon="el-icon-picture-outline"
              @click="changeSpecsImg(scope.$index)"
            ></el-button>
            <el-button
              style="padding: 8px; margin-left: 10px"
              icon="el-icon-delete"
              @click="deleteSpecsImg(scope.$index)"
            ></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="is_sale" label="上架" width="70px">
        <template slot-scope="{ row }">
          <!-- 默认规格不显示 -->
          <el-switch
            v-if="Object.keys(row.config.attrs).length"
            v-model="row.is_sale"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <template v-if="goodsForm.pay_type === 0">
        <el-table-column prop="price" label="售卖价">
          <template slot-scope="{ row }">
            <el-form :model="row" :rules="priceRules" class="demo-form-inline">
              <el-form-item prop="price">
                <el-input
                  placeholder="请输入售卖价格"
                  v-model="row.price"
                  :max="999999"
                  maxlength="6"
                  type="number"
                ></el-input>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column prop="market_price" label="市场价(选填)">
          <template slot-scope="{ row }">
            <!-- 踩坑：v-model.number 不支持小数，遂弃用。改为 type=number -->
            <el-input
              placeholder="请输入市场价格"
              v-model="row.market_price"
              :max="999999"
              maxlength="6"
              type="number"
            ></el-input>
          </template>
        </el-table-column>
      </template>
      <el-table-column v-else label="积分兑换数量">
        <template slot-scope="{ row }">
          <el-form
            :model="row"
            :rules="{
              integral: [
                {
                  required: true,
                  message: '请输入积分数量',
                  trigger: 'blur',
                },
              ],
            }"
            class="demo-form-inline"
          >
            <el-form-item prop="integral">
              <el-input-number
                placeholder="请输入"
                v-model="row.integral"
                :max="999999"
                maxlength="6"
                class="w-100"
                controls-position="right"
              ></el-input-number>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="stocks" label="库存">
        <template slot-scope="{ row }">
          <el-form
            :model="row"
            :rules="{
              stocks: [
                { required: true, message: '请输入库存', trigger: 'blur' },
              ],
            }"
            class="demo-form-inline"
          >
            <el-form-item prop="stocks">
              <el-input
                placeholder="请输入库存"
                v-model.number="row.stocks"
                :max="999999"
                maxlength="6"
              ></el-input>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
    </el-table>
    <!-- 图片弹窗 -->
    <media-selector
      v-model="showMedia"
      :max="1"
      type="image"
      @select="handlePhoto"
    >
    </media-selector>
  </div>
</template>

<script>
import ListImage from '@/base/components/List/ListImage'
import MediaSelector from '@/modules/common/components/MediaSelector.vue'

export default {
  components: { ListImage, MediaSelector },
  model: {
    prop: 'value',
    event: 'changeValue',
  },
  props: {
    value: {
      type: Array,
    },
    //商品属性
    attrs: {
      type: Array,
      default: () => [],
    },
    goodsForm: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    // 规格
    specs: {
      get() {
        if (this.value.length) {
          return this.value
        } else {
          let defaultSpecs = [
            {
              id: 0,
              config: {
                name: '默认规格',
                attrs: {},
              },
              is_sale: 1,
              image: '',
              price: '',
              market_price: '',
              stocks: '',
              integral: '',
            },
          ]
          this.$emit('changeValue', defaultSpecs)

          return defaultSpecs
        }
      },
      set(val) {
        this.$emit('changeValue', val)
      },
    },
    // 批量修改规格 options
    specsOptions: {
      get() {
        let specsOptionsArr = []
        this.attrs.forEach((item) => {
          let options = [{ label: `全部${item.name}`, value: 'all' }]
          item.children.forEach((child) => {
            options.push({
              label: child.name,
              value: child.name,
            })
          })
          // console.log(this.form.options[item.name], 'name')
          // 默认为 all 全选
          if (!this.form.options[item.name]) {
            this.$set(this.form.options, item.name, 'all')
          }
          specsOptionsArr.push({ name: item.name, arr: options })
        })
        return specsOptionsArr
      },
    },
  },
  watch: {
    'goodsForm.pay_type'(newValue) {
      if (newValue === 1) {
        //   积分
        this.specs = this.specs.map((el) => {
          return {
            ...el,
            price: '',
            market_price: '',
          }
        })
      } else {
        //   人民币
        this.specs = this.specs.map((el) => {
          return {
            ...el,
            integral: '',
          }
        })
      }
    },
  },
  data() {
    return {
      showMedia: false,
      currentI: 0,
      // 批量设置表单
      form: {
        price: '',
        market_price: '',
        integral: '',
        stocks: '',
        options: {},
      },
      priceRules: {
        price: [
          { required: true, message: '请输入售卖价', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入售卖价'))
              }
              if (typeof parseFloat(value) === 'number') {
                callback()
              } else {
                callback(new Error('售卖价必须是数字'))
              }
            },
            message: '售卖价必须是数字',
            trigger: 'change',
          },
        ],
      },
    }
  },
  methods: {
    // 改变规格图片
    changeSpecsImg(index) {
      this.currentI = index
      this.showMedia = true
    },
    // 删除规格图片
    deleteSpecsImg(index) {
      this.$confirm(
        '是否删除规格图片?  删除后默认使用封面图作为规格图片。',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        this.specs[index].image = ''

        this.$message({
          type: 'success',
          message: '删除成功!',
        })
      })
    },
    //确认
    handlePhoto(mediaList) {
      if (mediaList.length) {
        this.specs[this.currentI].image = mediaList[0].url
      }
    },
    // 批量设备规格
    batchSet() {
      let optionsKeyArr = Object.keys(this.form.options)
      optionsKeyArr.forEach((key, keyIndex) => {
        let item = this.form.options[key]
        this.specs.forEach((el) => {
          this.compareSpecs(
            el,
            el.config.attrs[key],
            item,
            key,
            keyIndex,
            optionsKeyArr
          )
        })
      })

      // 设置成功后清空
      this.form.price = ''
      this.form.market_price = ''
      this.form.stocks = ''
      this.form.integral = ''
    },
    // 批量设置递归规格比较
    compareSpecs(el, elAttr, item, key, keyIndex, optionsKeyArr) {
      // console.log(el, item, optionsKeyArr[keyIndex + 1])

      if (item === 'all') {
        if (optionsKeyArr[keyIndex + 1] === undefined) {
          // 批量设置全部规格
          this.allBatchSetSpecs()
          return
        }
        this.compareSpecs(
          el,
          el.config.attrs[optionsKeyArr[keyIndex + 1]],
          this.form.options[optionsKeyArr[keyIndex + 1]],
          optionsKeyArr[keyIndex + 1],
          keyIndex + 1,
          optionsKeyArr
        )

        // console.log(el, el.config.attrs[key], key, item)
      }
      // 找到包含的规格,判断是不是全选
      else if (elAttr === item) {
        // console.log(el, item)
        if (this.form.price) {
          el.price = this.form.price
        }
        if (this.form.market_price) {
          el.market_price = this.form.market_price
        }
        if (this.form.stocks) {
          el.stocks = this.form.stocks
        }
        if (this.form.integral) {
          el.integral = this.form.integral
        }
      }
    },
    // 批量设置全部规格
    allBatchSetSpecs() {
      this.specs.forEach((el) => {
        el.price = this.form.price
        el.market_price = this.form.market_price
        el.stocks = this.form.stocks
        el.integral = this.form.integral
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div class="list-content" v-loading="loading">
    <el-form
      class="save-content"
      label-width="150px"
      :model="form"
      :rules="rules"
      ref="goodForm"
    >
      <p class="save-title" style="margin-top: 4px">基础信息</p>
      <el-form-item label="数据类型：" prop="data_type">
        <el-select
          style="min-width: 295px"
          v-model="form.data_type"
          placeholder="请选择"
          @change="switchDataType()"
        >
          <el-option
            v-for="item in dataTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品名称：" prop="name">
        <el-input placeholder="请输入商品名称" v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item label="商品分类：" prop="category_id">
        <el-select
          style="min-width: 295px"
          v-model="form.category_id"
          placeholder="请选择"
        >
          <el-option
            v-for="item in categoryOption"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          :style="{
            marginLeft: '12px',
          }"
          type="primary"
          @click="showAddCategory = true"
          >添加分类</el-button
        >
<!--        <el-button type="plain" @click="getCategoryList()">刷新</el-button>-->
      </el-form-item>

      <!-- 商城才显示 -->
      <template v-if="form.data_type">
        <el-form-item label="商品描述：" prop="description">
          <el-input
            placeholder="请输入商品描述"
            v-model="form.description"
          ></el-input>
        </el-form-item>
        <el-form-item class="scroll-res" label="商品图片和视频：" prop="res">
          <media-wall
            :limit="9 - form.res.length < 0 ? 0 : 9 - form.res.length"
            addText="上传内容"
            :width="150"
            :upload-type="['image', 'video']"
            :height="100"
            v-model="form.res"
            :draggable="true"
            @handleMediaSrc="handleMediaSrc"
          >
          </media-wall>
          <p class="tips">
            最多上传9个素材，其中最多上传1个视频（mp4格式），视频时长不超过60秒<br />
            视频大小不超过200M。拖拽图片或视频可进行排序。
          </p>
        </el-form-item>
        <p class="save-title" style="margin-top: 4px">商品属性</p>
        <el-form-item label="商品属性：" prop="attrs">
          <AddGoodsAttr
            @changeValue="attrChange"
            v-model="form.attrs"
          ></AddGoodsAttr>
        </el-form-item>
        <p class="save-title" style="margin-top: 4px">规格售价与库存</p>
        <el-form-item class="" prop="" label="支付类型：">
          <!--          支付类型（0:人民币、1:积分）-->
          <el-radio-group v-model="form.pay_type">
            <el-radio :label="0"> 人民币</el-radio>
            <el-radio :label="1"> 积分</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="scroll-specs" prop="specs">
          <AddGoodsSpecs
            v-model="form.specs"
            :attrs="form.attrs"
            :goods-form="form"
          ></AddGoodsSpecs>
        </el-form-item>
        <p class="save-title" style="margin-top: 4px">商品参数</p>
        <el-form-item label="商品参数：" prop="params">
          <AddGoodsParams v-model="form.params"></AddGoodsParams>
        </el-form-item>
        <p class="save-title" style="margin-top: 4px">服务保障</p>
        <el-form-item label="服务保障：" prop="services">
          <AddGoodsService v-model="form.services"></AddGoodsService>
        </el-form-item>
        <p class="save-title" style="margin-top: 4px">物流设置</p>
        <!--        <el-form-item label="物流设置：" prop="deliver_enable">-->
        <!--&lt;!&ndash;          物流设置开启（0:不开启，1:开启，默认开启）&ndash;&gt;-->
        <!--          <el-radio-group v-model="form.deliver_enable">-->
        <!--            <el-radio :label="1">开启</el-radio>-->
        <!--            <el-radio :label="0">关闭</el-radio>-->
        <!--          </el-radio-group>-->
        <!--        </el-form-item>-->
        <template v-if="form.deliver_enable">
          <el-form-item label="商品地址：" prop="address">
            <!-- <el-button
              type="primary"
              @click="openAddressSelector = true"
              style="max-width: none"
              >{{ form.address ? form.address : "请选择" }}</el-button
            >
            <AddressSelector
              v-model="openAddressSelector"
              @change="handleChangeAddress"
              :detailForm="addressForm"
            /> -->
            <el-cascader
              :props="{ value: 'label' }"
              @change="handleChange"
              v-model="form.address"
              :options="cityJson"
              filterable
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="发货时间：" prop="deliver_time">
            <el-select v-model="form.deliver_time" placeholder="请选择">
              <el-option
                v-for="item in deliverTimeOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="邮费设置：" prop="postage_type">
            <el-radio-group v-model="form.postage_type">
              <el-radio :label="0">包邮</el-radio>
              <el-radio :label="1">自定义邮费</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="form.postage_type"
            label="邮费记价："
            prop="postage_min"
          >
            <el-input
              style="width: 100px; margin-right: 5px"
              v-model="form.postage_min"
            ></el-input
            >件内
            <el-input
              style="width: 100px; margin-right: 5px"
              v-model="form.postage_min_price"
            ></el-input
            >元,每增加
            <el-input
              style="width: 100px; margin-right: 5px"
              v-model="form.postage_add"
            ></el-input
            >件,增加
            <el-input
              style="width: 100px; margin-right: 5px"
              v-model="form.postage_add_price"
            ></el-input
            >元
          </el-form-item>
        </template>

        <p class="save-title" style="margin-top: 4px">商品详情</p>

        <el-form-item label="商品详情：" prop="details">
          <tinymce
            style="width: 650px"
            ref="editor"
            @choose="handleToolBarClick"
            v-model="form.details"
            :show-article-import="false"
          ></tinymce>
        </el-form-item>
        <p class="save-title" style="margin-top: 4px">购买设置</p>
        <el-form-item label="库存扣减：" prop="stock_type">
          <el-radio-group v-model="form.stock_type">
            <el-radio :label="0">下单减库存</el-radio>
            <el-radio :label="1">付款减库存</el-radio>
          </el-radio-group>
          <p class="tips">
            {{
              form.stock_type
                ? '存在超卖风险，超卖后可前往订单中心取消订单或发货'
                : '存在拍下后不付款，恶意占用库存的风险'
            }}
          </p>
        </el-form-item>
        <el-form-item label="购买限制：" prop="buy_limit">
          <el-switch
            v-model="form.buy_limit"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
          <div v-if="form.buy_limit">
            每人
            <el-select
              style="width: 120px; margin-right: 5px"
              v-model="form.buy_limit_type"
              placeholder="请选择"
            >
              <el-option
                v-for="item in limitTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            限购
            <el-input
              style="width: 60px; margin-right: 5px"
              v-model="form.buy_limit_value"
            ></el-input
            >件
            <p class="tips">每日0：00刷新限购资格</p>
          </div>
        </el-form-item>
        <!-- 先隐藏---------- -->
        <!-- <p class="save-title" style="margin-top: 4px">功能设置</p>
        <el-form-item label="评价功能：" prop="comment_status">
          <el-switch
            v-model="form.comment_status"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="送礼功能：" prop="gift_status">
          <el-switch
            v-model="form.gift_status"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item> -->
      </template>
      <!-- 微信小商店才显示 -->
      <template v-else>
        <el-form-item label="店铺名称：" prop="shop_id">
          <el-select
            v-model="form.shop_id"
            :placeholder="!shopArr.length ? '暂无店铺' : '请选择店铺'"
            :disabled="!shopArr.length"
          >
            <el-option
              v-for="(item, index) in shopArr"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
          <div class="form-tip" v-if="!shopArr.length">
            <span
              class="el-icon-info"
              style="font-size: 15px; color: #c0c4cc"
            ></span>
            <span
              >暂无店铺，点击前往
              <router-link
                :to="{ name: 'WxStoreConfig' }"
                target="_blank"
                class="cursor-pointer"
                >创建小商店
              </router-link></span
            >
          </div>
        </el-form-item>
        <el-form-item label="商品编号：" prop="spu_id">
          <el-input
            v-model="form.spu_id"
            placeholder="请填写商品编号"
          ></el-input>
          <div class="form-tip">
            <span
              class="el-icon-info"
              style="font-size: 15px; color: #c0c4cc"
            ></span>
            <span>微信小商店-商品列表中的SpuId</span>
          </div>
        </el-form-item>
        <el-form-item label="商品价格：" prop="price">
          <el-input
            class="num-input"
            v-model="form.price"
            type="number"
            placeholder="请填写商品展示价格"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="商品图片：" prop="image">
          <SingleMediaWall
            v-model="form.image"
            :width="100"
            :height="100"
            :borderRadius="6"
          >
          </SingleMediaWall>
        </el-form-item>
      </template>
    </el-form>

    <FixedActionBar :z-index="2">
      <el-button @click="handleCancel">取消</el-button>
      <el-button
        type="primary"
        v-if="form.id === 0 || form.status === 2"
        @click="saveConfig(0)"
        >上架</el-button
      >

      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>

    <!-- 富文本插入图片 -->
    <media-selector v-model="showImageList" multiple @select="updateImages" />

    <media-selector
      v-model="showVideoList"
      type="video"
      multiple
      @select="updateVideos"
    />
    <media-selector
      v-model="showAudioList"
      type="audio"
      multiple
      @select="updateAudios"
    />
    <!-- 文章导入 -->
    <import-article v-model="isShowImportDialog" @confirm="getImportDetail" />
    <!-- 导入HTML -->
    <import-html-code v-model="isShowHtmlInput" />

    <AddCategory
      :showAddCategory="showAddCategory"
      :editObj="editObj"
      @closeAddDialog="closeAddDialog"
      @updateData="updateData"
    ></AddCategory>
    <add-category
  </div>
</template>

<script>
const regionData = require('@/modules/common/json/address.json')
import AddCategory from '../components/GoodsCategory/AddCategory'

// import {
//   getCycCircleSendConfig,
//   setCycCircleSendConfig,
// } from "../api/circle-send-config";
import FixedActionBar from '@/base/layout/FixedActionBar'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall'
import { getCategory } from '../api/shop-list'
import MediaWall from '@/modules/common/components/MediaWall.vue'
import AddressSelector from '@/modules/common/components/AddressSelector.vue'

import AddGoodsAttr from '../components/GoodsDetail/AddGoodsAttr.vue'
import AddGoodsSpecs from '../components/GoodsDetail/AddGoodsSpecs.vue'
import AddGoodsService from '../components/GoodsDetail/AddGoodsService.vue'
import AddGoodsParams from '../components/GoodsDetail/AddGoodsParams.vue'

// 富文本相关组件
import Tinymce from '@/base/components/Editor/Tinymce'
import MediaSelector from '@/modules/common/components/MediaSelector'
import ImportArticle from '@/base/components/Editor/ImportArticle'
import ImportHtmlCode from '@/base/components/Editor/ImportHtmlCode'
// 接口
import {
  saveGoods,
  getGoods,
  storeAll,
  saveWxGoods,
} from '../api/goods-detail.js'
export default {
  components: {
    AddCategory,
    FixedActionBar,
    SingleMediaWall,
    MediaWall,
    AddGoodsAttr,
    AddGoodsSpecs,
    AddGoodsService,
    AddGoodsParams,
    AddressSelector,
    Tinymce,
    MediaSelector,
    ImportArticle,
    ImportHtmlCode,
  },
  data() {
    return {
      loading: false,
      form: {
        id: 0,
        res: [],
        attrs: [],
        specs: [],
        address: [],
        price: 0 /* 微信小商店的商品 */,
        lat: 0,
        lng: 0,
        services: [],
        params: [],
        deliver_time: '',
        stock_type: 0,
        buy_limit: 0,
        comment_enable: 1,
        gift_enable: 1,
        postage_type: 0,
        data_type: 1,
        postage_min: 0,
        postage_min_price: 0,
        postage_add: 0,
        postage_add_price: 0,
        details: '',
        buy_limit_type: 0,
        buy_limit_value: 0,
        pay_type: 0, //支付类型（0:人民币、1:积分）
        deliver_enable: 1, //物流设置开启（0:不开启，1:开启，默认开启）
      },
      // 是否允许保存
      allowSave: true,
      addressForm: {
        address: '',
        lat: 0,
        lng: 0,
      },
      // 校验规则
      rules: {
        data_type: [
          { required: true, message: '请选择数据类型', trigger: 'change' },
        ],

        category_id: [
          { required: true, message: '请选择分类', trigger: 'change' },
        ],
        res: [
          {
            required: true,
            message: '请选择商品图片和视频',
            trigger: 'blur',
          },
        ],
        address: [
          { required: true, message: '请选择商品地址', trigger: 'change' },
        ],
        deliver_time: [
          { required: true, message: '请选择发货时间', trigger: 'change' },
        ],
        postage_type: [
          { required: true, message: '请选择邮费设置', trigger: 'change' },
        ],
        postage_min: [
          { required: true, message: '请自定义邮费', trigger: 'change' },
        ],
        shop_id: [{ required: true, message: '请选择店铺', trigger: 'change' }],
        spu_id: [
          { required: true, message: '请填写商品编号', trigger: 'blur' },
        ],
        name: [{ required: true, message: '请填写商品名称', trigger: 'blur' }],
        price: [
          { required: true, message: '请填写商品价格', trigger: 'blur' },
          { type: 'number', message: 'age must be a number' },
          // @Example { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
          {
            // min: 0, // 这个是校验字符串长度的
            validator: (rule, value, callback) => {
              if (value > 0) {
                callback()
              } else {
                callback(new Error('商品价格不小于0'))
              }
            },
            message: '商品价格不小于0',
            trigger: 'blur',
          },
        ],
        image: [
          { required: true, message: '请上传商品图片', trigger: 'change' },
        ],
        specs: [{ required: true, message: '请填写规格', trigger: 'change' }],
      },
      id: 0,
      shopArr: [], // 店铺选项列表
      // 商品分类选项
      categoryOption: [],
      // 商品类型选项
      dataTypeOptions: [
        {
          value: 1,
          label: '商城商品',
        },
        {
          value: 0,
          label: '微信小商店商品',
        },
      ],
      // 限购类型选项
      limitTypeOptions: [
        {
          label: '每日',
          value: 0,
        },
        {
          label: '每周',
          value: 1,
        },
        {
          label: '每月',
          value: 2,
        },
      ],
      openAddressSelector: false,
      // 发货时间选择项
      deliverTimeOptions: [
        '4小时',
        '8小时',
        '12小时',
        '16小时',
        '20小时',
        '24小时',
        '48小时',
        '3天',
        '5天',
        '7天',
        '10天',
        '12天',
        '14天',
        '16天',
        '20天',
        '25天',
        '30天',
        '35天',
        '45天',
      ],
      // 城市数据
      cityJson: regionData,

      // 富文本相关
      showImageList: false,
      showVideoList: false,
      showAudioList: false,
      isShowImportDialog: false,
      isShowHtmlInput: false,

      showAddCategory: false,
      editObj: {},
    }
  },
  watch: {
    'form.res': {
      handler(val) {
        this.handleMediaSrc(val)
      },
      deep: true,
    },
  },
  created() {
    if (this.$route.params.id != 0) {
      this.form.id = this.$route.params.id
      this.getDatail()
    }
    this.getCategoryList()
    this.getShoplist()
  },
  methods: {
    closeAddDialog() {
      this.showAddCategory = false
      this.editObj = {}
    },
    handleEditCategory(data) {
      this.editObj = JSON.parse(JSON.stringify(data))
      this.showAddCategory = true
    },
    updateData({ name }) {
      this.showAddCategory = false
      this.getCategoryList(name)
    },
    switchDataType() {
      this.$refs.goodForm.clearValidate()
      if (this.form.data_type) {
        this.form.spu_id = ''
        this.form.shop_id = ''
      }
    },
    handleChange(value) {
      console.log(value)
    },
    // 分类列表
    getCategoryList(name = '') {
      getCategory().then((res) => {
        this.categoryOption = res.data.filter((item) => {
          if (item.name !== '全部') {
            return item
          }
        })
        if (name) {
          this.form.category_id =
            this.categoryOption.find((c) => c.name === name)?.id || ''
          if (this.form.category_id)
            this.$nextTick(() => {
              this.$refs.goodForm.clearValidate('category_id')
            })
        }
      })
    },
    // 获取商品详情
    getDatail() {
      this.loading = true
      getGoods({ id: this.form.id })
        .then((res) => {
          // 市场价为零表示没有填
          res.data.specs.forEach((item) => {
            if (item.market_price === 0) {
              item.market_price = ''
            }
          })
          res.data.address = res.data.address.split('/')
          this.form = res.data

          this.addressForm.address = res.data.address
          this.addressForm.lat = res.data.lat
          this.addressForm.lng = res.data.lng
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 获取店铺列表
    getShoplist() {
      storeAll().then((res) => {
        this.shopArr = res.data.filter((el) => el.id !== -1)
      })
    },
    // 滚动到指定类名的dom
    scrollToClassDom(className) {
      setTimeout(() => {
        this.$nextTick(() => {
          let scrollDom = document.getElementsByClassName(className)
          if (!scrollDom || !scrollDom.length) return
          scrollDom[0].scrollIntoView({ block: 'center' })
        })
      }, 100)
    },
    // 保存配置
    saveConfig(status) {
      if (!this.allowSave) {
        this.$message.warning('只可上传一个商品视频!')
        this.scrollToClassDom('scroll-res')

        return
      }
      let postData = {
        ...this.form,
      }
      // 商城商品才校验检测
      if (!postData.res.length && postData.data_type) {
        this.$message.error('请上传商品图片或者视频!')
        this.scrollToClassDom('scroll-res')
        return
      }
      // 校验规格是否可以保存
      let allowObj = {
        flag: true,
        name: '',
        nullName: '',
        isNotNull: true,
      }
      if (postData.data_type) {
        postData.specs.forEach((item) => {
          if (
            parseFloat(item.market_price) &&
            parseFloat(item.market_price) <= item.price
          ) {
            // 市场价小于售卖价格，不可保存
            allowObj.flag = false
            if (!allowObj.name) {
              // 规格名称
              allowObj.name = item.config.name
            }
          }
          if (postData.pay_type === 1) {
            //   积分商品

            console.log(item.integral, postData.pay_type, '===========')

            if (item.integral < 1) {
              new Error('积分必须大于0')
              allowObj.isNotNull = false
              if (!allowObj.name) {
                // 规格名称
                allowObj.name = item.config.name
              }
            }
          } else {
            //   普通商品
          }
          /**
           * 2024年07月19日16:38:56 库存允许为0，表示缺货
           *  || !item.stocks
           */
          if (!item.price && !item.integral) {
            // 没有填写完成信息
            allowObj.isNotNull = false
            if (!allowObj.nullName) {
              // 规格名称
              allowObj.nullName = item.config.name
            }
          }
        })
      }
      if (!allowObj.flag) {
        // 市场价小于售卖价格，不可保存
        this.$message.error(`规格${allowObj.name}的市场价必须大于等于售卖价`)
        this.scrollToClassDom('scroll-specs')
        return
      }
      if (!allowObj.isNotNull) {
        // 规格信息没有填写完整
        this.$message.error(`请填写完整${allowObj.nullName}的信息`)
        this.scrollToClassDom('scroll-specs')
        return
      }
      postData.specs.forEach((item) => {
        // 市场价为空的话设置为0
        if (!item.market_price) {
          item.market_price = 0
        }
        // 规格图片为空的话默认封面图
        if (!item.image) {
          if (postData.res.length) {
            item.image = postData.res[0].thumbnail || postData.res[0].url
          }
        }
        if (!item.integral) {
          item.integral = 0
        }

        if (!item.price) {
          item.price = 0
        }
      })

      // 上架
      if (status === 0) {
        postData.status = 0
      } else if (this.form.id === 0) {
        // 新增商品保存  到待上架
        postData.status = 1
      }
      console.log(postData)

      postData.deliver_time = postData.deliver_time || 0

      if (this.form.data_type) {
        // 保存商城数据
        this.$refs.goodForm.validate((valid) => {
          if (valid) {
            this.loading = true
            postData.attrs = JSON.stringify(postData.attrs)
            postData.params = JSON.stringify(postData.params)
            postData.services = JSON.stringify(postData.services)
            postData.address = postData.address.join('/')
            saveGoods(postData)
              .then((res) => {
                this.$message.success(res.msg)
                if (this.form.id) {
                  this.getDatail()
                } else {
                  this.$router.push({
                    name: 'ShopList',
                  })
                }

                this.loading = false
              })
              .catch((err) => {
                this.loading = false
              })
          } else {
            setTimeout(() => {
              this.$nextTick(() => {
                let isError = document.getElementsByClassName('is-error')
                console.log('isError', isError)
                isError[0].scrollIntoView({ block: 'center' })
              })
            }, 100)
            this.$message.error('请填写完整信息')
            return false
          }
        })
      } else {
        // 保存微信小商店数据
        this.$refs.goodForm.validate((valid) => {
          if (valid) {
            this.loading = true
            const data = { ...this.form }
            saveWxGoods(data)
              .then((res) => {
                this.$message.success(res.msg)
                if (this.form.id) {
                  this.getDatail()
                } else {
                  this.$router.push({
                    name: 'ShopList',
                  })
                }
                this.loading = false
              })
              .catch((err) => {
                this.loading = false
              })
          } else {
            setTimeout(() => {
              this.$nextTick(() => {
                let isError = document.getElementsByClassName('is-error')
                console.log('isError', isError)
                isError[0].scrollIntoView({ block: 'center' })
              })
            }, 100)
            this.$message.error('请填写完整信息')
            return false
          }
        })
      }
    },
    // 属性改变
    attrChange(val) {
      console.log(val, '属性')
      let result = []
      let curList = []
      let layer = 0
      this.descartes(val, result, layer, curList)
      console.log(result, 'result')
      let tempSpecs = JSON.parse(JSON.stringify(this.form.specs))
      this.form.specs = []
      result.forEach((item) => {
        let specs = {
          id: 0,
          config: {
            name: [],
            attrs: {},
          },
          is_sale: 1,
          image: '',
          price: '',
          market_price: '',
          stocks: '',
        }

        item.forEach((el) => {
          console.log(el, 'el')
          specs.config.name.push(el.value.name)
          specs.config.attrs[el.name] = el.value.name
          // 属性设置可配图并且有 设置图
          if (el.is_set_image && el.value.image) {
            console.log('有图')
            specs.image = el.value.image
          }
        })
        specs.config.name = specs.config.name.join(' ')
        console.log(specs, 'specs')
        // 之前规格

        let isAdd = false
        console.log(tempSpecs, specs, '0000')
        tempSpecs.forEach((itemSpecs, i) => {
          // 之前有相同的规格，做合并操作
          if (itemSpecs.config.name === specs.config.name) {
            isAdd = true
            let specsItem = {
              ...specs,
              ...itemSpecs,
              config: { ...specs.config },
            }
            // 如果规格没有图片，用属性的图
            if (specsItem.image && specs.image) {
              specsItem.image = specs.image
            }
            this.form.specs.push(specsItem)
            console.log(this.form.specs, 'addSpecs')
          }
        })
        if (!isAdd) {
          // 没有相同的规格，直接添加
          this.form.specs.push(specs)
          console.log(this.form.specs, 'addSpecs')
        }
      })
    },
    // 计算商品规格
    descartes(dataValues, result, layer, curList) {
      if (layer < dataValues.length - 1) {
        if (dataValues[layer].children.length == 0) {
          this.descartes(dataValues, result, layer + 1, curList)
        } else {
          for (let i = 0; i < dataValues[layer].children.length; i++) {
            let list = JSON.parse(JSON.stringify(curList))
            console.log(list)

            let data = {
              name: dataValues[layer].name,
              value: dataValues[layer].children[i],
              is_set_image: dataValues[layer].is_set_image,
            }
            list.push(data)

            this.descartes(dataValues, result, layer + 1, list)
          }
        }
      } else if (layer == dataValues.length - 1) {
        if (dataValues[layer].children.length == 0) {
          result.push(curList)
        } else {
          for (let i = 0; i < dataValues[layer].children.length; i++) {
            let list = JSON.parse(JSON.stringify(curList))
            let data = {
              name: dataValues[layer].name,
              value: dataValues[layer].children[i],
              is_set_image: dataValues[layer].is_set_image,
            }
            list.push(data)
            result.push(list)
          }
        }
      }
    },
    // 选择地址
    handleChangeAddress(data) {
      console.log(data)
      this.form.address = data.address
      this.form.lat = data.lat
      this.form.lng = data.lng
      this.addressForm = data
    },
    // 取消保存
    handleCancel() {
      this.$router.push({
        name: 'ShopList',
      })
    },
    // 商品图片视频改变
    handleMediaSrc(val) {
      let count = 0
      val.forEach((item) => {
        if (item.type === 'video') {
          count++
        }
      })
      console.log(count, val)
      if (count > 1) {
        this.allowSave = false
        this.$message.warning('只可上传一个商品视频!')
      } else {
        this.allowSave = true
      }
    },
    // ------富文本相关
    updateImages(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<img src="${item.url}"/>`
        )
      })
    },
    updateVideos(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
          // `<video poster="${item.config.thumbnail}" controls src="${item.url}"></video>`
        )
      })
    },
    // 富文本点击事件
    handleToolBarClick(e) {
      switch (e.type) {
        case 'image':
          this.showImageList = true
          break
        case 'video':
          this.showVideoList = true
          break
        case 'audio':
          this.showAudioList = true
          break
        case 'import':
          // this.isShowImportDialog = true;
          break
        case 'html':
          this.isShowHtmlInput = true
          break
      }
    },
    updateAudios(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          'mceReplaceContent',
          false,
          `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
        )
      })
    },
    // 导入公众号文章内容
    getImportDetail(e) {
      console.log(this.form)
      this.$set(this.form, 'content', e.content)
      this.$refs.editor.$emit('change', e.content)
    },
  },
}
</script>

<style lang="scss" scoped>
.save-title {
  padding: 0 8px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  border-left: 3px #3479ef solid;
  line-height: 16px;
  margin-bottom: 19px;
}

.save-content {
  padding: 12px 4px 10px;

  .el-form-item__label,
  ::v-deep.el-form-item__label {
    white-space: nowrap;
  }
  .el-input {
    max-width: 295px;
  }
}

.tips {
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
</style>

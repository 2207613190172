<template>
  <!-- 商品属性选择 -->
  <div>
    <div class="add-subjects">
      <div class="subject-list" v-show="myValue.length !== 0">
        <draggable
          v-model="myValue"
          animation="500"
          @end="changeSort"
          draggable=".draggable"
        >
          <div
            class="single-subject draggable"
            v-for="(item, index) in myValue"
            :key="index"
          >
            <div class="item">
              <div class="content">
                <div class="attr-box">
                  <div class="left">
                    <div class="attr-name">属性{{ index + 1 }}</div>
                    <el-input
                      style="width: 200px"
                      placeholder="请输入属性名称"
                      v-model="myValue[index].name"
                      clearable
                      @change="attrInput"
                    ></el-input>
                    <el-radio
                      v-model="attrImgSetIndex"
                      :label="index"
                      @input="handelSet"
                      >配图</el-radio
                    >
                  </div>
                  <div class="right">
                    <el-button size="mini" @click="removeLine(index)"
                      >删除属性</el-button
                    >
                  </div>
                </div>
                <div
                  class="attr-child"
                  v-for="(childItem, childI) in myValue[index].children"
                >
                  <el-input
                    style="width: 200px"
                    :placeholder="`请输入${myValue[index].name || '子属性'}`"
                    v-model="myValue[index].children[childI].name"
                    clearable
                    @change="attrInput"
                  ></el-input>
                  <!-- 属性图片 -->
                  <list-image
                    v-if="
                      myValue[index].children[childI].image &&
                      attrImgSetIndex === index
                    "
                    :src="myValue[index].children[childI].image"
                    style="margin-left: 10px"
                    fit="cover"
                  ></list-image>
                  <div class="button-group">
                    <el-button
                      icon="el-icon-picture-outline"
                      v-if="attrImgSetIndex === index"
                      @click="setAttrPhoto(index, childI)"
                    ></el-button>
                    <el-button
                      :disabled="childI === 0"
                      icon="el-icon-arrow-up"
                      @click="changeSequence('up', index, childI)"
                    ></el-button>
                    <el-button
                      icon="el-icon-arrow-down"
                      :disabled="childI === myValue[index].children.length - 1"
                      @click="changeSequence('down', index, childI)"
                    ></el-button>
                    <el-button
                      v-if="myValue[index].children.length > 1"
                      @click="childDelete(index, childI)"
                      icon="el-icon-delete"
                    ></el-button>
                  </div>
                </div>
                <el-button
                  class="add-child"
                  type="text"
                  size="small"
                  icon="el-icon-circle-plus-outline"
                  @click="addChildAttr(index)"
                  >创建子属性</el-button
                >
              </div>
            </div>
          </div>
        </draggable>
      </div>
      <p class="img-tips" v-if="myValue.length">提示：拖拽可以调整排序</p>
      <el-button
        class="subject-add-btn"
        type="text"
        size="small"
        @click="addLine"
        icon="el-icon-circle-plus-outline"
        >创建新属性</el-button
      >
    </div>
    <!-- 图片弹窗 -->
    <media-selector
      v-model="showMedia"
      :max="1"
      type="image"
      @select="handlePhoto"
    >
    </media-selector>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import MediaSelector from "@/modules/common/components/MediaSelector.vue";
import ListImage from "@/base/components/List/ListImage";

export default {
  name: "AddGoodsAttr",
  components: { draggable, MediaSelector, ListImage },
  model: {
    prop: "value",
    event: "changeValue",
  },
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      loading: true,
      // 设置配图的属性 索引
      attrImgSetIndex: 0,
      myValue: [],
      // 展示图片选择弹窗
      showMedia: false,
      // 当前操作的属性
      currentI: 0,
      // 当前操作的子属性
      currentChildI: 0,
      timer: "",
    };
  },
  watch: {
    value: {
      handler(val) {
        console.log(val);
        this.myValue = val;
      },
      immediate: true,
      // deep: true,
    },
    myValue(val) {
      console.log(this.myValue, "val");
    },
  },
  computed: {},
  created() {
    console.log(this.myValue);
  },
  mounted() {},
  methods: {
    // 移除
    removeLine(i) {
      this.$confirm("是否删除此属性?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.myValue.splice(i, 1);
          this.$emit("changeValue", this.myValue);

          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {});
    },
    // 添加属性
    addLine() {
      this.myValue.push({
        name: "",
        is_set_image: 1,
        children: [{ name: "", image: "" }],
      });
      this.$emit("changeValue", this.myValue);
    },
    // 添加子属性
    addChildAttr(i) {
      this.myValue[i].children.push({
        name: "",
        image: "",
      });
      this.$emit("changeValue", this.myValue);
    },
    // 删除子属性
    childDelete(index, childI) {
      this.$confirm("是否删除此子属性?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.myValue[index].children.splice(childI, 1);
          this.$emit("changeValue", this.myValue);

          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {});
    },
    // 顺序改变
    changeSort({ oldIndex, newIndex }) {
      if (oldIndex === newIndex) {
        return;
      }
      console.log(oldIndex, newIndex, this.attrImgSetIndex);
      // 如果商品之前是选中配图的，需要修改配图索引
      if (oldIndex === this.attrImgSetIndex) {
        this.attrImgSetIndex = newIndex;
      } else if (newIndex === this.attrImgSetIndex) {
        this.attrImgSetIndex = oldIndex;
      }

      this.$emit("changeValue", this.myValue);
    },
    // 改变子属性顺序
    changeSequence(type, index, childI) {
      if (type === "up") {
        // 子属性跟上一个子属性位置交换
        let tempObj = {};
        tempObj = this.myValue[index].children[childI];
        this.$set(
          this.myValue[index].children,
          childI,
          this.myValue[index].children[childI - 1]
        );
        this.$set(this.myValue[index].children, childI - 1, tempObj);
      } else if (type === "down") {
        // 子属性跟下一个子属性位置交换
        let tempObj = {};
        tempObj = this.myValue[index].children[childI];
        this.$set(
          this.myValue[index].children,
          childI,
          this.myValue[index].children[childI + 1]
        );
        this.$set(this.myValue[index].children, childI + 1, tempObj);
      }
      this.$emit("changeValue", this.myValue);
    },
    // 属性配图索引改变
    handelSet(i) {
      this.myValue.forEach((item, index) => {
        if (i === index) {
          item.is_set_image = 1;
        } else {
          item.is_set_image = 0;
          // 清空图片数据
          //   this.myValue[index].children.forEach((el, elI) => {
          //     el.image = "";
          //   });
        }
      });
      this.$emit("changeValue", this.myValue);
    },
    //确认
    handlePhoto(mediaList) {
      console.log(mediaList);
      if (mediaList.length) {
        this.myValue[this.currentI].children[this.currentChildI].image =
          mediaList[0].url;
        console.log(this.myValue);
      }
      this.$emit("changeValue", this.myValue);
    },
    // 设置子属性弹窗
    setAttrPhoto(index, childI) {
      this.currentI = index;
      this.currentChildI = childI;
      this.showMedia = true;
    },
    attrInput(e) {
      this.$emit("changeValue", this.myValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-subjects {
  .img-tips {
    width: 194px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999;
    line-height: 12px;
    margin-top: 10px;
  }
  .subject-list {
    width: 700px;

    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #f5f7fa;
    .single-subject {
      display: flex;
      justify-content: space-between;
      .item {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 5px;
        color: #909399;
        padding: 10px;
        .content {
          .attr-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            .left {
              flex: 1;
              display: flex;
              align-items: center;
              .attr-name {
                width: 50px;
              }
              .el-input {
                margin-right: 10px;
              }
              span {
                margin-left: 10px;
              }
              span.active {
                color: #409eff;
              }
            }

            .right {
              el-button {
              }
            }
          }
          .attr-child {
            display: flex;
            align-items: center;
            .el-input {
              width: 200px;
              margin-left: 50px;
            }

            .button-group {
              margin-left: 10px;
              .el-button {
                padding: 4px;
              }
            }
          }

          .attr-child + .attr-child {
            margin-top: 10px;
          }
          .attr-child:last-child {
            margin-bottom: 10px;
          }
          .add-child {
            padding: 0;
            margin-left: 50px;
          }
        }
      }
    }
    .single-subject + .single-subject {
      margin-top: 10px;
    }
  }

  .subject-add-btn {
    margin-top: 10px;
    padding: 0;
  }
}
</style>

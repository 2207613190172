<template>
  <div>
    <div class="add-subjects">
      <div class="subject-list" v-show="myValue.length !== 0">
        <draggable
          v-model="myValue"
          animation="500"
          @end="changeSort"
          draggable=".draggable"
        >
          <div
            class="single-subject draggable"
            v-for="(item, index) in myValue"
            :key="index"
          >
            <div class="item">
              <div class="content">
                <div class="attr-box">
                  <div class="left">
                    <div class="attr-name">参数{{ index + 1 }}</div>
                    <el-input
                      style="width: 200px"
                      placeholder="请输入参数名称"
                      v-model="myValue[index].name"
                      clearable
                      @blur="attrInput"
                    ></el-input>
                    <el-input
                      style="width: 200px"
                      :placeholder="`请输入${
                        myValue[index].name || '参数内容'
                      }`"
                      v-model="myValue[index].value"
                      clearable
                      @blur="attrInput"
                    ></el-input>
                  </div>
                  <div class="right">
                    <div class="button-group">
                      <el-button
                        @click="removeLine(index)"
                        icon="el-icon-delete"
                      ></el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </draggable>
      </div>
      <p class="img-tips" v-if="myValue.length">提示：拖拽可以调整排序</p>
      <el-button
        class="subject-add-btn"
        type="text"
        size="small"
        @click="addLine"
        icon="el-icon-circle-plus-outline"
        >创建新参数</el-button
      >
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "AddGoodsAttr",
  components: { draggable },
  model: {
    prop: "value",
    event: "changeValue",
  },
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      loading: true,

      myValue: [],

      // 当前操作的属性
      currentI: 0,
    };
  },
  watch: {
    value: {
      handler(val) {
        console.log(val);
        this.myValue = val;
      },
      immediate: true,
      // deep: true,
    },
    myValue(val) {
      console.log(this.myValue, "val");
    },
  },
  computed: {},
  created() {
    console.log(this.myValue);
  },
  mounted() {},
  methods: {
    // 移除
    removeLine(i) {
      this.$confirm("是否删除此属性?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.myValue.splice(i, 1);
          this.$emit("changeValue", this.myValue);

          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {});
    },
    // 添加服务
    addLine() {
      this.myValue.push({
        name: "",
        value: "",
      });
      this.$emit("changeValue", this.myValue);
    },

    // 顺序改变
    changeSort({ oldIndex, newIndex }) {
      console.log(oldIndex, newIndex, this.attrImgSetIndex);

      this.$emit("changeValue", this.myValue);
    },

    // 使用 @input 会页面卡死
    attrInput(e) {
      console.log(e);
      this.$emit("changeValue", this.myValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-subjects {
  .img-tips {
    width: 194px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999;
    line-height: 12px;
    margin-top: 10px;
  }
  .subject-list {
    width: 700px;

    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #f5f7fa;
    .single-subject {
      display: flex;
      justify-content: space-between;
      .item {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 5px;
        color: #909399;
        padding: 10px;
        .content {
          .attr-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
              flex: 1;
              display: flex;
              align-items: center;
              .attr-name {
                width: 50px;
              }
              .el-input {
                margin-right: 10px;
              }
              span {
                margin-left: 10px;
              }
              span.active {
                color: #409eff;
              }
            }
            .right {
              el-button {
              }
            }
          }
          .attr-child {
            display: flex;
            align-items: center;
            .el-input {
              width: 200px;
              margin-left: 50px;
            }

            .button-group {
              margin-left: 10px;
              .el-button {
                padding: 4px;
              }
            }
          }

          .attr-child + .attr-child {
            margin-top: 10px;
          }
          .attr-child:last-child {
            margin-bottom: 10px;
          }
          .add-child {
            padding: 0;
            margin-left: 50px;
          }
        }
      }
    }
    .single-subject + .single-subject {
      margin-top: 10px;
    }
  }

  .subject-add-btn {
    margin-top: 10px;
    padding: 0;
  }
}
</style>
